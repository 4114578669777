import { AHrefExternal, SPLink } from '../../components/SPLink'
import { Configuration } from '../../../kentico-types/configuration'
import { getTranslation } from '../../splus-lib/utils/languageUtils'
import { urls } from '../../urls'
import { useComponentDidMount } from '../../splus-lib/utils/hooks/hooks'
import React, { useState } from 'react'

const currentYear = new Date().getFullYear()

type Props = {
  topOverlay: any
  content?: Configuration
}
const Footer = ({ topOverlay, content }: Props) => {
  const [mid, setMid] = useState('')

  useComponentDidMount(() => {
    const url = window.location.href
    const seqUrlIndex = url.indexOf('mid')

    if (seqUrlIndex !== -1) {
      setMid(url.substr(seqUrlIndex + 4))
    }
  })

  return (
    <React.Fragment>
      <div
        className={
          topOverlay
            ? 'saps-footer saps-page-container saps-footer--has-top-overlay'
            : 'saps-footer saps-page-container'
        }
      >
        <div className='saps-footer-top'>
          <img src={'/static/footer-top-image.svg'} alt='' className='saps-footer-top__img' />
        </div>
        <div className='saps-footer-content'>
          <div className='container saps-footer-container'>
            {mid === '' && (
              <div className='row saps-footer-row' data-component='footer-menu'>
                <div className='col-12 col-md'>
                  <div className='saps-footer-group'>
                    <h4 className='saps-footer-title saps-mb-20'>
                      {getTranslation('footer.title.information')}
                    </h4>
                    <ul className='saps-footer-links-list'>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={urls.sitemap()}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.sitemap')}
                        </SPLink>
                      </li>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={urls.overviewModels()}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.makes')}
                        </SPLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-md'>
                  <div className='saps-footer-group'>
                    <h4 className='saps-footer-title saps-mb-20'>
                      {getTranslation('footer.title.privacy')}
                    </h4>
                    <ul className='saps-footer-links-list'>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={
                            content?.odkazNaNastaveniCookies.value ??
                            'https://www.skoda-auto.com/system/cookies-settings'
                          }
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.cookies')}
                        </SPLink>
                      </li>
                      {/* <li className='saps-footer-links-list__item'>
                        <a
                          href={'#splusCookiesBar'}
                          className='saps-js-addClass saps-footer-links-list__link'
                          data-add-class='saps-cookies-bar--active'
                        >
                          {getTranslation('footer.item.cookies.settings')}
                        </a>
                      </li> */}
                      <li className='saps-footer-links-list__item'>
                        <AHrefExternal
                          href='https://privacyportal-de.onetrust.com/webform/4f52de0a-110f-4524-8986-02d49fa567c3/b52d4bbc-8309-429d-bcbb-fe8618251619'
                          target='_blank'
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                          rel='noreferrer'
                        >
                          {getTranslation('footer.item.suggestions')}
                        </AHrefExternal>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-md order-md-4'>
                  <div className='saps-footer-group'>
                    <h4 className='saps-footer-title saps-mb-20'>
                      {getTranslation('footer.title.other')}
                    </h4>
                    <ul className='saps-footer-links-list'>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={urls.contact()}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.contact')}
                        </SPLink>
                      </li>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={urls.faq()}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.questions')}
                        </SPLink>
                      </li>
                      {/* <li className='saps-footer-links-list__item'>
                        {LOCALE === 'cs' && (
                          <AHrefExternal
                            href='https://www.skodamabudoucnost.cz/'
                            className='saps-footer-links-list__link'
                            data-component='footer-menu'
                          >
                            {getTranslation('footer.item.career')}
                          </AHrefExternal>
                        )}
                        {LOCALE === 'sk' && (
                          <AHrefExternal
                            href='https://www.skoda-mojabuducnost.sk/'
                            className='saps-footer-links-list__link'
                            data-component='footer-menu'
                          >
                            {getTranslation('footer.item.career')}
                          </AHrefExternal>
                        )}
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-md order-md-3 saps-mt-25 saps-mt-0-md'>
                  <div className='saps-footer-group'>
                    <h4 className='saps-footer-title saps-mb-20'>
                      {getTranslation('footer.title.services')}
                    </h4>
                    <ul className='saps-footer-links-list'>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={urls.financing()}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.financing')}
                        </SPLink>
                      </li>
                      <li className='saps-footer-links-list__item'>
                        <SPLink
                          href={getTranslation('footer.item.insurance.link')}
                          className='saps-footer-links-list__link'
                          data-component='footer-menu'
                        >
                          {getTranslation('footer.item.insurance')}
                        </SPLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <p className='saps-footer-claim'>
              {getTranslation('footer.claim.text').replace('{{YEAR}}', currentYear.toString())}
            </p>
            <div className='saps-footer-sp-bar'>
              <div className='saps-footer-sp-bar-content'>
                <div className='d-flex justify-content-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 120.99 22.28'
                    height='16'
                    width='78'
                    className='d-block saps-mb-30'
                  >
                    <path
                      /* eslint-disable-next-line max-len */
                      d='M6.3.44l2.21 2.51c.12.15.25.2.44.2h4.63c.2 0 .31-.05.43-.2l2.2-2.51c.2-.22.13-.44-.19-.44H12.9c-.2 0-.32.07-.44.22l-.94 1.35H11L10.06.22A.53.53 0 0 0 9.6 0H6.5c-.31 0-.38.22-.2.44m40.83 12.64v.24c0 8.45 1.38 8.96 11.53 8.96 10.15 0 11.53-.51 11.53-8.99v-.21c0-8.45-1.38-8.96-11.53-8.96-10.15 0-11.53.5-11.53 8.96m-1.03 8.4l-9.64-9.42 9.06-7.14c.26-.2.16-.44-.17-.44h-5.91c-.17 0-.3.05-.44.17l-9.25 7.39v-7.2c0-.24-.12-.36-.36-.36h-4.5c-.25 0-.37.12-.37.36v16.71c0 .24.12.37.36.37h4.5c.25 0 .37-.13.37-.37v-4.21l2.74-2.16 6.63 6.54c.15.15.27.2.46.2h6.35c.31 0 .41-.22.17-.44m37.41-17h-9.66c-.24 0-.37.12-.37.36v16.71c0 .24.13.37.37.37h9.66c10.34 0 11.7-.51 11.7-8.65v-.15c0-8.13-1.36-8.64-11.7-8.64m37.43 17L112.6 4.75c-.08-.2-.22-.27-.42-.27h-7.4c-.2 0-.35.07-.42.27L96 21.48c-.13.27-.03.44.26.44h4.82c.2 0 .34-.08.41-.27l1.26-2.47h11.43l1.26 2.47c.08.2.22.27.42.27h4.81c.3 0 .4-.17.27-.44m-56.37-8.4v.24c0 4.9-.2 4.96-5.91 4.96-5.72 0-5.91-.07-5.91-4.96v-.24c0-4.9.2-4.97 5.9-4.97 5.72 0 5.92.07 5.92 4.97m25.14 0v.24c0 4.48-.36 4.62-6.17 4.62h-4.8V8.45h4.8c5.8 0 6.17.15 6.17 4.63m22.63 2.44h-7.75l3.56-7.05h.63zM.58 21.94c2.57.12 6.76.24 9.17.24 10.57 0 11.83-.02 11.83-5.76 0-4.53-1.33-4.9-6.93-5.06l-6.49-.2c-2.98-.1-3.03-.4-3.03-1.74 0-1.38.37-1.38 7.51-1.38 1.35 0 5.67.07 7.94.12.24 0 .37-.12.37-.36V4.84c0-.24-.13-.36-.34-.38-2.26-.13-5.94-.25-8.58-.25C1.67 4.21 0 4.41 0 9.76c0 4.58 1.01 5.18 7.65 5.4l5.96.2c2.78.09 2.83.43 2.83 1.47 0 1.43-.12 1.5-6.7 1.5-3.08 0-6.26-.12-9.14-.22-.24-.02-.36.1-.36.34v3.1c0 .24.12.37.34.4'
                      fill='#414141'
                    />
                  </svg>
                </div>
                <p className='saps-p saps-bold saps-mb-0 saps-color-text'>
                  {getTranslation('footer.claim.plus')}
                </p>
              </div>
            </div>
            <div className='saps-footer-sp-bar'>
              <div className='saps-footer-sp-bar-content'>
                <div className='d-flex justify-content-center'>
                  <img
                    className='img-fluid saps-p-10'
                    src='/static/SKO_Plus_1080x1920_RGB_vertical_black.png'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Footer
